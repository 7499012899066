/** @format */

import { Table, Modal, Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AliwangwangOutlined, DashboardOutlined } from "@ant-design/icons";
//import plats from "../../data.json";
import { useNavigate } from "react-router-dom";
import { Excel } from "antd-table-saveas-excel";
import { GetPlatData, UpdateBIOS } from "../../apicalls/inout";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import ClockLoader from "react-spinners/ClockLoader";
// https://eddieup.github.io/antd-table-saveas-excel/2api
function PlatformsList() {
  const override = {
    marginTop: 3,
  };
  const navigate = useNavigate();
  const defaultPageSize = 20;
  const [page, setPage] = React.useState(1);
  const [pagesize, setPagesize] = React.useState(defaultPageSize);
  const [filterInput, setFilterInput] = React.useState("");
  const [isViewing, setisViewing] = React.useState(false);
  const [viewdata, setViewdata] = React.useState(null);
  const onChange = (e) => {
    setFilterInput(e.target.value);
  };
  const [dataSource, setDataSource] = useState([]); //plats.myplats);
  //  const dataSource = useSelector((state) => state.platforms.dataSource);
  const dispatch = useDispatch();
  const getPlat = async () => {
    let data = await GetPlatData();
    if (data.length > 0) {
      setDataSource(data);
    }
  };

  useEffect(() => {
    getPlat();
  }, []);
  function ButtonCell({ record }) {
    // console.log("RECS", record);
    const [loading, setLoading] = useState(false);
    return loading ? (
      <ClockLoader
        size={18}
        aria-label="Loading Spinner"
        data-testid="loader"
        color="#36d7b7"
        cssOverride={override}
      />
    ) : (
      <Tooltip title="check bios">
        <DashboardOutlined
          onClick={() => {
            onCheckbios(record);
            setLoading(true);
          }}
          style={{
            fontSize: "17px",
            color: "brown",
            marginLeft: 2,
          }}
        />
      </Tooltip>
    );
  }
  function getLocalDateTime() {
    var str = new Date();
    var str_time = str.toLocaleString("en-US", {
      timeZone: "Asia/Kuala_Lumpur",
      hour: "numeric",
      hourCycle: "h24",
      minute: "numeric",
      second: "numeric",
    });
    var str_date = str.toLocaleString("en-US", {
      timeZone: "Asia/Kuala_Lumpur",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    str_date = "date" + str_date.split("/").reverse().join("-");
    str_time = "time" + str_time.split(":").join("-");
    return str_date + "_" + str_time;
  }

  const filterData = () => {
    if (filterInput === "") return dataSource;
    const lowerCased = filterInput.toLowerCase();
    return dataSource?.filter(
      ({
        date,
        platform,
        name,
        site,
        status,
        workload,
        activities_comments,
        user,
        team,
        cpu1_sn,
        cpu2_sn,
        stack,
        socket,
        thread,
        total_ram,
        dimm_sticks,
        dimm_size,
        bios,
        os,
        ssh,
        bmc,
        pdu,
        pdu_usr_pass,
      }) =>
        date.toString().toLowerCase().includes(lowerCased) ||
        platform.toString().toLowerCase().includes(lowerCased) ||
        name.toString().toLowerCase().includes(lowerCased) ||
        site.toString().toLowerCase().includes(lowerCased) ||
        status.toString().toLowerCase().includes(lowerCased) ||
        workload.toString().toLowerCase().includes(lowerCased) ||
        activities_comments.toString().toLowerCase().includes(lowerCased) ||
        user
          .toString()
          .replace(/[\W_]+/g, "")
          .toLowerCase()
          .includes(lowerCased) ||
        team.toString().toLowerCase().includes(lowerCased) ||
        cpu1_sn.toString().toLowerCase().includes(lowerCased) ||
        cpu2_sn.toString().toLowerCase().includes(lowerCased) ||
        stack.toString().toLowerCase().includes(lowerCased) ||
        socket.toString().toLowerCase().includes(lowerCased) ||
        thread.toString().toLowerCase().includes(lowerCased) ||
        total_ram.toString().toLowerCase().includes(lowerCased) ||
        dimm_sticks.toString().toLowerCase().includes(lowerCased) ||
        dimm_size.toString().toLowerCase().includes(lowerCased) ||
        bios.toString().toLowerCase().includes(lowerCased) ||
        os.toString().toLowerCase().includes(lowerCased) ||
        ssh.toString().toLowerCase().includes(lowerCased) ||
        bmc.toString().toLowerCase().includes(lowerCased) ||
        pdu.toString().toLowerCase().includes(lowerCased) ||
        pdu_usr_pass.toString().toLowerCase().includes(lowerCased)
    );
  };

  const columns = [
    {
      key: "uactions",
      title: "View",
      dataIndex: "actions",
      fixed: "left",
      render: (id, record, index) => {
        return (
          <>
            <div className="flex flex-row">
              <span>({(page - 1) * pagesize + index + 1})</span>
              <Tooltip title="view">
                <AliwangwangOutlined
                  onClick={() => {
                    setisViewing(true);
                    onViewing(record);
                  }}
                  style={{
                    fontSize: "18px",
                    color: "blue",
                    marginLeft: 3,
                    marginRight: 4,
                  }}
                />
              </Tooltip>
              <ButtonCell record={record} />
            </div>
          </>
        );
      },
    },
    {
      key: "udate",
      title: "Date",
      dataIndex: "date",
      defaultSortOrder: "descend", //latest first
      sorter: (a, b) =>
        new Date(a.date.toString()) - new Date(b.date.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "uplatform",
      title: "Platform",
      dataIndex: "platform",
      sorter: (a, b) =>
        a.platform.toString().localeCompare(b.platform.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "uname",
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.toString().localeCompare(b.name.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "usite",
      title: "Site",
      dataIndex: "site",
      sorter: (a, b) => a.site.toString().localeCompare(b.site.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "ubios",
      title: "BIOS",
      dataIndex: "bios",
      sorter: (a, b) => a.bios.toString().localeCompare(b.bios.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "ustatus",
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.toString().localeCompare(b.status.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uworkload",
      title: "Workload",
      dataIndex: "workload",
      sorter: (a, b) =>
        a.workload.toString().localeCompare(b.workload.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uactivities_comments",
      title: "Activities/Comments",
      dataIndex: "activities_comments",
      sorter: (a, b) =>
        a.activities_comments
          .toString()
          .localeCompare(b.activities_comments.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uuser",
      title: "User",
      dataIndex: "user",
      sorter: (a, b) =>
        a.user
          .toString()
          .replace(/[\W_]+/g, "")
          .localeCompare(b.user.toString().replace(/[\W_]+/g, "")),
      sortDirections: ["ascend", "descend", "ascend"],
      // To trim backslash and special characters in JavaScript, you can use the replace() method with a regular expression. The syntax is:
      // str = str.replace(/[\W_]+/g, '');
    },
    {
      key: "uteam",
      title: "Team",
      dataIndex: "team",
      sorter: (a, b) => a.team.toString().localeCompare(b.team.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ucpu1_sn",
      title: "Cpu1 SN",
      dataIndex: "cpu1_sn",
      sorter: (a, b) =>
        a.cpu1_sn.toString().localeCompare(b.cpu1_sn.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ucpu2_sn",
      title: "Cpu2 SN",
      dataIndex: "cpu2_sn",
      sorter: (a, b) =>
        a.cpu2_sn.toString().localeCompare(b.cpu2_sn.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ustack",
      title: "STACK",
      dataIndex: "stack",
      sorter: (a, b) => a.stack.toString().localeCompare(b.stack.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "usocket",
      title: "Socket",
      dataIndex: "socket",
      sorter: (a, b) => a.socket.toString().localeCompare(b.socket.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uthread",
      title: "Thread",
      dataIndex: "thread",
      sorter: (a, b) => a.thread.toString().localeCompare(b.thread.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "utotal_ram",
      title: "Total RAM (GB)",
      dataIndex: "total_ram",
      sorter: (a, b) =>
        a.total_ram.toString().localeCompare(b.total_ram.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "udimm_sticks",
      title: "DIMM sticks",
      dataIndex: "dimm_sticks",
      sorter: (a, b) =>
        a.dimm_sticks.toString().localeCompare(b.dimm_sticks.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "udimm_size",
      title: "DIMM Size (GB)",
      dataIndex: "dimm_size",
      sorter: (a, b) =>
        a.dimm_size.toString().localeCompare(b.dimm_size.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      key: "uos",
      title: "OS",
      dataIndex: "os",
      sorter: (a, b) => a.os.toString().localeCompare(b.os.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ussh",
      title: "SSH",
      dataIndex: "ssh",
      sorter: (a, b) => a.ssh.toString().localeCompare(b.ssh.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ubmc",
      title: "BMC",
      dataIndex: "bmc",
      sorter: (a, b) => a.bmc.toString().localeCompare(b.bmc.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => (
        <a href={record.bmc} target="_blank">
          {text}
        </a>
      ),
    },
    {
      key: "updu",
      title: "PDU",
      dataIndex: "pdu",
      sorter: (a, b) => a.pdu.toString().localeCompare(b.pdu.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "updu_usr_pass",
      title: "PDU usr/pass",
      dataIndex: "pdu_usr_pass",
      sorter: (a, b) =>
        a.pdu_usr_pass.toString().localeCompare(b.pdu_usr_pass.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uonline",
      title: "Online",
      dataIndex: "online",
      hidden: true,
    },
  ];
  const colstodisplay = columns.filter((item) => !item.hidden);
  const handlePrint = () => {
    const excel = new Excel();
    var str = getLocalDateTime();
    //remove the 'operation' index from the columns array by: if not error will come
    //actually not needed in this file, as there is no operation key
    const mycols = columns.filter((col) => col.key !== "uactions");
    //make title become dataIndex as cannot
    const mcols = mycols?.map((obj, i) => ({
      ...obj,
      title: obj["dataIndex"],
    }));
    function convertToDate(dateString) {
      //https://stackoverflow.com/questions/33299687/how-to-convert-dd-mm-yyyy-string-into-javascript-date-object
      //  Convert a "dd-MM-yyyy" string into a Date object
      let d = dateString.split("/");
      let dat = new Date(d[2] + "-" + d[1] + "-" + d[0]);
      console.log("data datassss", dat);
      return dat;
    }
    //here we convert date from "21-Nov-2023" to "Sat Dec 31 2022 00:00:00 GMT+0800 (Singapore Standard Time)", goes into Excel as 5/10/2023 16:00 (time allowable)
    //https://www.codevertiser.com/update-javascript-array-of-object/
    var dPrintSource = filterData().map((el) => ({
      ...el,
      ...{ date: convertToDate(el.date) },
    }));

    excel
      .addSheet("sheet 1")
      .addColumns(mcols)
      .addDataSource(dPrintSource, {
        str2Percent: true,
      })
      .saveAs(`snapshot_${str}.xlsx`);
    //using data in the addDataSource, as this is a backup and not saving by filtered data...
  };
  function resetViewing() {
    setisViewing(false);
  }
  function onViewing(record) {
    setViewdata(record);
  }
  async function onCheckbios(record) {
    // console.log("chk bios", record);
    let data = await UpdateBIOS(record.name);
    // console.log("mydata", data);
    toast.success(data.message, {
      duration: 4000,
      style: {
        border: "1px solid #713200",
        color: "#713200",
      },
      iconTheme: {
        primary: "#713200",
        secondary: "#FFFAEE",
      },
    });
    getPlat();
    //API to backend to pin the server and get bios info and display it
  }
  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-1 mt-1">
        {/* https://codesandbox.io/s/funny-silence-rscxq?fontsize=14&file=/index.js */}
        <Input.Search
          style={{
            width: 250,
            marginBottom: 10,
            marginLeft: 5,
            border: "1px solid blue",
            borderRadius: 5,
          }}
          placeholder="search text"
          allowClear
          enterButton="Search"
          size="small"
          onChange={onChange}
          // onSearch={setFilterInput} not needed, as we got onChange (dynamic)
        />
        <div className="flex flex-row justify-between items-center">
          <h2>RPM (Rapid Platform Manager)</h2>
        </div>
        <div className="flex flex-row justify-between items-center">
          <button
            style={{ width: 120, marginRight: 3 }}
            className="button-4"
            onClick={() => navigate("/mtce")}
          >
            Goto Mtce
          </button>
          <button
            style={{ width: 100, marginRight: 3 }}
            className="button-45"
            onClick={handlePrint}
          >
            Snapshot
          </button>
        </div>
      </div>
      {/* https://github.com/ant-design/ant-design/issues/8392 
      https://ant.design/components/table#components-table-demo-expand*/}
      {/* https://github.com/ant-design/ant-design/issues/7623 rowKey */}
      <Table
        rowKey="id"
        style={{ marginTop: 42 }}
        bordered
        size="small"
        scroll={{ x: "max-content" }}
        className="table-striped-rows"
        rowClassName={() => "editable-row"}
        columns={colstodisplay}
        dataSource={filterData()}
        pagination={{
          defaultPageSize,
          position: ["topRight", "bottomRight"],
          pageSizeOptions: [defaultPageSize, "50", "100"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          onChange(pge, pSize) {
            setPage(pge);
            setPagesize(pSize);
          },
        }}
      />
      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          shape: "round",
          style: { backgroundColor: "lightgreen" },
        }}
        width="700px"
        // cancelButtonProps={{
        //   shape: "round",
        //   style: { backgroundColor: "orange" },
        // }}
        style={{
          top: 30,
          paddingBottom: 0,
          overflowY: "auto",
          maxHeight: "calc(100vh - 100px)",
          maxWidth: "800px",
        }}
        title="View Platform Info"
        open={isViewing}
        okText="Ok"
        onCancel={() => resetViewing()}
        onOk={() => {
          onViewing();
          resetViewing();
        }}
      >
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col justify-between items-center">
            <span className="flex flex-col myspan">
              <label htmlFor="date">Date</label>
              <input readOnly id="date" type="text" value={viewdata?.date} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="platform">Platform</label>
              <input
                readOnly
                id="platform"
                type="text"
                value={viewdata?.platform}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="name">Name</label>
              <input readOnly id="name" type="text" value={viewdata?.name} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="site">Site</label>
              <input readOnly id="site" type="text" value={viewdata?.site} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="status">Status</label>
              <input
                readOnly
                id="status"
                type="text"
                value={viewdata?.status}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="workload">Workload</label>
              <input
                readOnly
                id="workload"
                type="text"
                value={viewdata?.workload}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="activities_comments">Activities/Comments</label>
              <input
                readOnly
                id="activities_comments"
                type="text"
                value={viewdata?.activities_comments}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="user">User</label>
              <input readOnly id="user" type="text" value={viewdata?.user} />
            </span>
          </div>
          <div className="flex flex-col justify-between items-center">
            <span className="flex flex-col myspan">
              <label htmlFor="team">Team</label>
              <input readOnly id="team" type="text" value={viewdata?.team} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="cpu1_sn">CPU1 SN</label>
              <input
                readOnly
                id="cpu1_sn"
                type="text"
                value={viewdata?.cpu1_sn}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="cpu2_sn">CPU2 SN</label>
              <input
                readOnly
                id="cpu2_sn"
                type="text"
                value={viewdata?.cpu2_sn}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="stack">STACK</label>
              <input readOnly id="stack" type="text" value={viewdata?.stack} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="socket">Socket</label>
              <input
                readOnly
                id="socket"
                type="text"
                value={viewdata?.socket}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="thread">Thread</label>
              <input
                readOnly
                id="thread"
                type="text"
                value={viewdata?.thread}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="total_ram">Total RAM</label>
              <input
                readOnly
                id="total_ram"
                type="text"
                value={viewdata?.total_ram}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="dimm_sticks">DIMM Sticks</label>
              <input
                readOnly
                id="dimm_sticks"
                type="text"
                value={viewdata?.dimm_sticks}
              />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="dimm_size">DIMM Size</label>
              <input
                readOnly
                id="dimm_size"
                type="text"
                value={viewdata?.dimm_size}
              />
            </span>
          </div>
          <div className="flex flex-col justify-between items-center">
            <span className="flex flex-col myspan">
              <label htmlFor="bios">BIOS</label>
              <input readOnly id="bios" type="text" value={viewdata?.bios} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="os">OS</label>
              <input readOnly id="os" type="text" value={viewdata?.os} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="ssh">SSH</label>
              <input readOnly id="ssh" type="text" value={viewdata?.ssh} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="bmc">BMC</label>
              <input readOnly id="bmc" type="text" value={viewdata?.bmc} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="pdu">PDU</label>
              <input readOnly id="pdu" type="text" value={viewdata?.pdu} />
            </span>
            <span className="flex flex-col myspan">
              <label htmlFor="pdu_usr_pass">PDU User password</label>
              <input
                readOnly
                id="pdu_usr_pass"
                type="text"
                value={viewdata?.pdu_usr_pass}
              />
            </span>
          </div>
        </div>
      </Modal>
      <Toaster position="bottom-left" reverseOrder={false} />
    </div>
  );
}

export default PlatformsList;
