/** @format */

import { configureStore } from "@reduxjs/toolkit";
import inoutReducer from "./inoutSlice";

const store = configureStore({
  reducer: {
    platforms: inoutReducer,
  },
});

export default store;
