/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Uploadexcel from "./components/Uploadexcel";
import "./stylesheets/alignments.css";
import "./stylesheets/sizes.css";
import "./stylesheets/form-elements.css";
import "./stylesheets/custom.css";
import "./stylesheets/theme.css";
import "./stylesheets/index.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="mtce" element={<Admin />} />
          <Route path="upload" element={<Uploadexcel />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
