import React, { useState, useRef, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { PostBulk } from "../apicalls/inout";
import { useDispatch } from "react-redux";
import {
  SetCloseBulkModal,
  SetDatasource,
  SetisOffline,
} from "../redux/inoutSlice";

//https://www.pluralsight.com/guides/uploading-files-with-reactjs
var xlsx = require("xlsx");
//https://dev.to/shareef/convert-excel-to-json-in-reactjs-in-just-2-steps-4pa1
//https://stackoverflow.com/questions/36280538/how-to-transform-all-json-keys-to-lowercase-in-javascript  convert keys to lowercase
export default function Uploadexcel() {
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [nogo, setNogo] = useState(false);
  const [myJSON, setmyJSON] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {});
  function ConvertKeysToLowerCase(obj) {
    var output = {};
    for (var i in obj) {
      if (Object.prototype.toString.apply(obj[i]) === "[object Object]") {
        output[i.toLowerCase()] = ConvertKeysToLowerCase(obj[i]);
      } else if (Object.prototype.toString.apply(obj[i]) === "[object Array]") {
        output[i.toLowerCase()] = [];
        output[i.toLowerCase()].push(ConvertKeysToLowerCase(obj[i][0]));
      } else {
        output[i.toLowerCase()] = obj[i];
      }
    }
    return output;
  }

  function getExtension(filename) {
    return filename.split(".").pop();
  }
  const resetFileInput = () => {
    // 👇️ reset input value
    inputRef.current.value = null;
    setmyJSON([]);
    setSelectedFile();
  };
  const changeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNogo(true);
      setSelectedFile(e.target.files[0]);
      if (e.target.files[0].size > 1 * 1000 * 1024) {
        toast.error("File with maximum size of 1MB is allowed", {
          duration: 4000,
        });
        setNogo(false);
        return false;
      }
      var extn = getExtension(e.target.files[0].name);
      if (extn !== "xlsx") {
        toast.error("Only .xlsx files can be uploaded", { duration: 4000 });
        setNogo(false);
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { defval: "" });
        let temps = ConvertKeysToLowerCase(json);
        setmyJSON(Object.values(temps));
        toast.success("Ready to go, press submit", { duration: 4000 });
        //  console.log("json output", json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  function ExcelDateToJSDate(serial) {
    //https://stackoverflow.com/questions/16229494/converting-excel-date-serial-number-to-date-using-javascript
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate()
      // hours,
      // minutes,
      // seconds
    );
  }
  Date.prototype.toShortFormat = function () {
    //https://stackoverflow.com/questions/27480262/get-current-date-in-dd-mon-yyy-format-in-javascript-jquery
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = this.getDate();

    const monthIndex = this.getMonth();
    const monthName = monthNames[monthIndex];

    const year = this.getFullYear();

    return `${day}-${monthName}-${year}`;
  };
  const handleSubmission = async () => {
    //only if nogo is true, allow api
    //if due to validation failed, say a png file uploaded, json will be []
    //myJSON is now an array

    myJSON.map((el) => {
      el.id = uuidv4();
	   el.name = el.name?.toString().toLowerCase();
      el.date = ExcelDateToJSDate(el.date).toShortFormat();
      el.online = el.online?.toString().toLowerCase().includes("true")
        ? true
        : false;
    });
    console.log("json output", myJSON);
    resetFileInput();
    if (nogo && !_.isEmpty(myJSON)) {
      var data = await PostBulk(myJSON);
      var myfilt = data.filter((el) => el.online === true);
      //filtering only the true values and then show it first..
      dispatch(SetDatasource(myfilt));
      toast.success("Upload is ok", { duration: 4000 });
      dispatch(SetCloseBulkModal(false));
    } else {
      toast.error("Upload file is empty", { duration: 4000 });
    }
    dispatch(SetisOffline(false));
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center w-400 cv-align filebox">
        <label htmlFor="file">Click here</label>
        <input
          ref={inputRef}
          type="file"
          name="file"
          id="file"
          onChange={changeHandler}
        />
        <div>
          <button
            style={{ height: 30, width: 70, marginTop: 15, paddingTop: 5 }}
            className="button-28"
            onClick={handleSubmission}
          >
            Submit
          </button>
        </div>
      </div>
      {/* <div>
        {!_.isEmpty(myJSON) &&
          myJSON.map((d, indx) => <li key={indx}>{d.os}</li>)}
      </div> */}
      <Toaster position="bottom-left" reverseOrder={false} />
    </>
  );
}
