/** @format */

import { Table, Modal, Input, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ApiOutlined,
  PlusCircleTwoTone,
  SisternodeOutlined,
  RollbackOutlined,
  HomeTwoTone,
} from "@ant-design/icons";

//https://github.com/aamirjaved844/AntDTableCRUD/tree/master
//https://www.youtube.com/watch?v=y4_nSE-aZhc
//auto focus Input antd, see reply of it-rcmart
//https://github.com/ant-design/ant-design/issues/8668
import React, { useState, useEffect } from "react";
import plats from "../../data.json";
import { Excel } from "antd-table-saveas-excel";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Uploadexcel from "../../components/Uploadexcel";
import { v4 as uuidv4 } from "uuid";
import {
  GetPlatData,
  GetAllPlatData,
  EditPlatData,
  DelPlatData,
  AddPlatData,
  EraseALL,
  OfflinePlatData,
} from "../../apicalls/inout";
import { useDispatch, useSelector } from "react-redux";
import {
  SetCloseBulkModal,
  SetDatasource,
  SetisOffline,
} from "../../redux/inoutSlice";
//https://codesandbox.io/s/dlzf3s Editable rows Antd table
//https://ant.design/components/table#components-table-demo-edit-cell
function Admin() {
  const defaultPageSize = 20;
  const [isAdding, setIsAdding] = useState(false);
  const [page, setPage] = React.useState(1);
  const [pagesize, setPagesize] = React.useState(defaultPageSize);
  const [isEditing, setIsEditing] = useState(false);

  const [editingPlatform, setEditingPlatform] = useState(null);
  const dataSource = useSelector((state) => state.platforms.dataSource);
  const [filterInput, setFilterInput] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bulk = useSelector((state) => state.platforms.closebulkmodal);
  const isoffline = useSelector((state) => state.platforms.isOffline);

  //delete platform
  async function delPlatform(name) {
    let data = await DelPlatData(name); //data will be [] as response
    //so we will update dataSource in state variable to appear record removed from DOM
    data = dataSource.filter((el) => el.name !== name);
    dispatch(SetDatasource(data));
  }
  //edit platform
  async function editPlatformfn(record) {
    // console.log("edit edit2", record);
    let xy = dataSource.filter((el) => el.name !== record.name);
    let data = await EditPlatData(record);
    //here we did not use server push back data as it will await and take a gap to
    //render. the xy just sorts and throws out the record and we dispatch to state
    // matrix by reacttach it, so it appears for viewer that data updated, which is fast
    // dispatch(SetDatasource(data["data"]["fws"]));
    dispatch(SetDatasource([...xy, record]));
  }
  //add single platform
  async function addPlatform(record) {
    let data = await AddPlatData(record);
    //same as edit, to make it faster, we will just add the new obj to state library
    //dispatch(SetDatasource(data));
    //console.log("it is a data", [...dataSource, ...record]);
    dispatch(SetDatasource([...dataSource, ...record]));
  }
  const getPlat = async () => {
    let data = await GetPlatData();
    if (data.length > 0) {
      dispatch(SetDatasource(data));
    }
  };
  useEffect(() => {
    getPlat();
    dispatch(SetisOffline(true));
  }, []);
  const columns = [
    {
      key: "uactions",
      title: "Actions",
      fixed: "left",
      render: (id, record, index) => {
        return (
          <>
            <span className="mr-1">({(page - 1) * pagesize + index + 1})</span>
            <EditOutlined
              onClick={() => {
                onEditPlatform(record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                onDeletePlatform(record);
              }}
              style={{ color: "red", marginLeft: 12 }}
            />
            {isoffline ? (
              <Tooltip title="push to mtce pool">
                <ApiOutlined
                  onClick={() => {
                    onMtcePlatform__off(record);
                  }}
                  style={{ color: "blue", marginLeft: 12 }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="push back to mainstream">
                <RollbackOutlined
                  style={{ color: "blue", marginLeft: 12 }}
                  onClick={() => {
                    onMtcePlatform__on(record);
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      key: "udate",
      title: "Date",
      dataIndex: "date",
      defaultSortOrder: "descend", //latest first
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "uplatform",
      title: "Platform",
      dataIndex: "platform",
      sorter: (a, b) =>
        a.platform.toString().localeCompare(b.platform.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "uname",
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.toString().localeCompare(b.name.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "usite",
      title: "Site",
      dataIndex: "site",
      sorter: (a, b) => a.site.toString().localeCompare(b.site.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "ubios",
      title: "BIOS",
      dataIndex: "bios",
      sorter: (a, b) => a.bios.toString().localeCompare(b.bios.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      key: "ustatus",
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.toString().localeCompare(b.status.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uworkload",
      title: "Workload",
      dataIndex: "workload",
      sorter: (a, b) =>
        a.workload.toString().localeCompare(b.workload.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uactivities_comments",
      title: "Activities/Comments",
      dataIndex: "activities_comments",
      sorter: (a, b) =>
        a.activities_comments
          .toString()
          .localeCompare(b.activities_comments.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uuser",
      title: "User",
      dataIndex: "user",
      sorter: (a, b) =>
        a.user
          .toString()
          .replace(/[\W_]+/g, "")
          .localeCompare(b.user.toString().replace(/[\W_]+/g, "")),
      sortDirections: ["ascend", "descend", "ascend"],
      // To trim backslash and special characters in JavaScript, you can use the replace() method with a regular expression. The syntax is:
      // str = str.replace(/[\W_]+/g, '');
    },
    {
      key: "uteam",
      title: "Team",
      dataIndex: "team",
      sorter: (a, b) => a.team.toString().localeCompare(b.team.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ucpu1_sn",
      title: "Cpu1 SN",
      dataIndex: "cpu1_sn",
      sorter: (a, b) =>
        a.cpu1_sn.toString().localeCompare(b.cpu1_sn.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ucpu2_sn",
      title: "Cpu2 SN",
      dataIndex: "cpu2_sn",
      sorter: (a, b) =>
        a.cpu2_sn.toString().localeCompare(b.cpu2_sn.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ustack",
      title: "Stack",
      dataIndex: "stack",
      sorter: (a, b) => a.stack.toString().localeCompare(b.stack.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "usocket",
      title: "Socket",
      dataIndex: "socket",
      sorter: (a, b) => a.socket.toString().localeCompare(b.socket.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "uthread",
      title: "Thread",
      dataIndex: "thread",
      sorter: (a, b) => a.thread.toString().localeCompare(b.thread.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "utotal_ram",
      title: "Total RAM (GB)",
      dataIndex: "total_ram",
      sorter: (a, b) =>
        a.total_ram.toString().localeCompare(b.total_ram.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "udimm_sticks",
      title: "DIMM sticks",
      dataIndex: "dimm_sticks",
      sorter: (a, b) =>
        a.dimm_sticks.toString().localeCompare(b.dimm_sticks.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "udimm_size",
      title: "DIMM Size",
      dataIndex: "dimm_size",
      sorter: (a, b) =>
        a.dimm_size.toString().localeCompare(b.dimm_size.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      key: "uos",
      title: "OS",
      dataIndex: "os",
      sorter: (a, b) => a.os.toString().localeCompare(b.os.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ussh",
      title: "SSH",
      dataIndex: "ssh",
      sorter: (a, b) => a.ssh.toString().localeCompare(b.ssh.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "ubmc",
      title: "BMC",
      dataIndex: "bmc",
      sorter: (a, b) => a.bmc.toString().localeCompare(b.bmc.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => (
        <a href={record.bmc} target="_blank">
          {text}
        </a>
      ),
    },
    {
      key: "updu",
      title: "PDU",
      dataIndex: "pdu",
      sorter: (a, b) => a.pdu.toString().localeCompare(b.pdu.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "updu_usr_pass",
      title: "PDU usr/pass",
      dataIndex: "pdu_usr_pass",
      sorter: (a, b) =>
        a.pdu_usr_pass.toString().localeCompare(b.pdu_usr_pass.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      key: "uonline",
      title: "Online",
      dataIndex: "online",
      hidden: true,
    },
  ];
  const colstodisplay = columns.filter((item) => !item.hidden);

  const onChange = (e) => {
    setFilterInput(e.target.value);
  };
  const handleAdd = () => {
    setIsEditing(true);
    setIsAdding(true); //to change modal title
    const newPlatform = {
      activities_comments: "some comments",
      bios: "3712",
      bmc: "https://yamuna-3764.test.com",
      stack: "8",
      date: "31-Dec-2023",
      dimm_size: "64G",
      dimm_sticks: "4",
      id: uuidv4(),
      name: "gaal-aah2",
      cpu1_sn: "2acc-3cdef-41cef",
      cpu2_sn: "21abc-31cde-42cef",
      os: "Ubuntu 22.04/Windows",
      pdu: "10.134.1.67 / 10.124.8.120 B4.P12",
      pdu_usr_pass: "admin/admin007(turn off both pdus)",
      platform: "yamuna",
      site: "BUD / IND / SRI",
      socket: "1",
      ssh: "tex-mydbg-yamuna-3764",
      status: "debug",
      team: "Garry",
      thread: "128",
      total_ram: "256G",
      user: "Sumesh",
      workload: "debug",
      online: true,
    };
    setEditingPlatform(newPlatform); //for push into modal and save after edit
  };
  const onBulkUpload = () => {
    dispatch(SetCloseBulkModal(true));
  };
  const showOffline = async () => {
    dispatch(SetisOffline(false));
    setPage(1);
    let data = await OfflinePlatData();
    dispatch(SetDatasource(data));
  };
  const showOnline = async () => {
    dispatch(SetisOffline(true));
    let data = await GetPlatData();
    dispatch(SetDatasource(data));
  };

  const onEraseAll = async () => {
    Modal.confirm({
      title:
        "Are you sure, you want to erase data from the database? Please backup before doing so...",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
       // var data = await EraseALL();
      //  dispatch(SetDatasource([])); //we set the state to zero, while above call sets zero in database
     //   toast.success(data.message, {
toast.success('Purposefully not active !!',{
   duration: 4000,
        });
      },
    });
  };
  const onMtcePlatform__off = async (record) => {
    //Push to mtce
    //setting online to false, to push the platform to mtce pool
    // we will call this same function api for pushing platform back to mainstream
    const off__it = { ...record, online: false };
    //not using another api, as the edit api is enough here
    var data = await EditPlatData(off__it);
    var myfilt = data?.filter((el) => el.online === true);
    dispatch(SetDatasource(myfilt));
  };
  const onMtcePlatform__on = async (record) => {
    //push back to main stream
    //setting online to false, to push the platform to mtce pool
    // we will call this same function api for pushing platform back to mainstream
    const on__it = { ...record, online: true };
    //not using another api, as the edit api is enough here
    var data = await EditPlatData(on__it);
    var myfilt = data.filter((el) => el.online === false);
    dispatch(SetDatasource(myfilt));
  };
  const onDeletePlatform = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this platform record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        // with data.json use... setDataSource((pre) => {
        //   //    pre.filter((platform) => console.log("AY AYE !!", record.id));
        //   return pre.filter((platform) => platform.id !== record.id);
        // });
        delPlatform(record.name);
        toast.success("Platform record has been deleted", {
          duration: 4000,
        });
      },
    });
  };
  const onEditPlatform = (record) => {
    setIsEditing(true);
    setEditingPlatform({ ...record });
  };
  const resetEditing = () => {
    setIsEditing(false);
    setIsAdding(false);
    setEditingPlatform(null);
  };
  const resetBulk = () => {
    dispatch(SetCloseBulkModal(false));
  };
  function getLocalDateTime() {
    var str = new Date();
    var str_time = str.toLocaleString("en-US", {
      timeZone: "Asia/Kuala_Lumpur",
      hour: "numeric",
      hourCycle: "h24",
      minute: "numeric",
      second: "numeric",
    });
    var str_date = str.toLocaleString("en-US", {
      timeZone: "Asia/Kuala_Lumpur",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    str_date = "date" + str_date.split("/").reverse().join("-");
    str_time = "time" + str_time.split(":").join("-");
    return str_date + "_" + str_time;
  }

  const filterData = () => {
    if (filterInput === "") return dataSource;
    const lowerCased = filterInput.toLowerCase();

    return dataSource.filter(
      ({
        date,
        platform,
        name,
        site,
        status,
        workload,
        activities_comments,
        user,
        team,
        cpu1_sn,
        cpu2_sn,
        stack,
        socket,
        thread,
        total_ram,
        dimm_sticks,
        dimm_size,
        bios,
        os,
        ssh,
        bmc,
        pdu,
        pdu_usr_pass,
      }) =>
        date.toString().toLowerCase().includes(lowerCased) ||
        platform.toString().toLowerCase().includes(lowerCased) ||
        name.toString().toLowerCase().includes(lowerCased) ||
        site.toString().toLowerCase().includes(lowerCased) ||
        status.toString().toLowerCase().includes(lowerCased) ||
        workload.toString().toLowerCase().includes(lowerCased) ||
        activities_comments.toString().toLowerCase().includes(lowerCased) ||
        user
          .toString()
          .replace(/[\W_]+/g, "")
          .toLowerCase()
          .includes(lowerCased) ||
        team.toString().toLowerCase().includes(lowerCased) ||
        cpu1_sn.toString().toLowerCase().includes(lowerCased) ||
        cpu2_sn.toString().toLowerCase().includes(lowerCased) ||
        stack.toString().toLowerCase().includes(lowerCased) ||
        socket.toString().toLowerCase().includes(lowerCased) ||
        thread.toString().toLowerCase().includes(lowerCased) ||
        total_ram.toString().toLowerCase().includes(lowerCased) ||
        dimm_sticks.toString().toLowerCase().includes(lowerCased) ||
        dimm_size.toString().toLowerCase().includes(lowerCased) ||
        bios.toString().toLowerCase().includes(lowerCased) ||
        os.toString().toLowerCase().includes(lowerCased) ||
        ssh.toString().toLowerCase().includes(lowerCased) ||
        bmc.toString().toLowerCase().includes(lowerCased) ||
        pdu.toString().toLowerCase().includes(lowerCased) ||
        pdu_usr_pass.toString().toLowerCase().includes(lowerCased)
    );
  };

  const handlePrint = async () => {
    //using data in the addDataSource, as this is a backup and not saving by filtered data...
    const excel = new Excel();
    var str = getLocalDateTime();
    //remove the 'actions' index from the columns array by: if not error will come
    const mycols = columns.filter((col) => col.key !== "uactions");
    //make title become dataIndex as cannot
    const mcols = mycols?.map((obj, i) => ({
      ...obj,
      title: obj["dataIndex"],
    }));
    function convertToDate(dateString) {
      //https://stackoverflow.com/questions/33299687/how-to-convert-dd-mm-yyyy-string-into-javascript-date-object
      //  Convert a "dd-MM-yyyy" string into a Date object
      let d = dateString.split("/");
      let dat = new Date(d[2] + "-" + d[1] + "-" + d[0]);

      return dat;
    }
    //here we convert date from "21-Nov-2023" to "Sat Dec 31 2022 00:00:00 GMT+0800 (Singapore Standard Time)", goes into Excel as 5/10/2023 16:00 (time allowable)
    //https://www.codevertiser.com/update-javascript-array-of-object/
    //Make a call to get the data as currently dataSource has the spli values of either online or offline as per user pressed button
    let mydatas = await GetAllPlatData();
    var dPrintSource = mydatas.map((el) => ({
      ...el,
      ...{ date: convertToDate(el.date) },
    }));

    excel
      .addSheet("sheet 1")
      .addColumns(mcols)
      .addDataSource(dPrintSource, {
        str2Percent: true,
      })
      .saveAs(`backup_${str}.xlsx`);

    //using data in the addDataSource, as this is a backup and not saving by filtered data...
  };
  return (
    <div className="">
      <header className="App-header">
        <div className="flex flex-row justify-between items-center mb-1 mt-1">
          <div className="flex flex-row justify-around items-center">
            <Input.Search
              style={{
                width: 250,
                marginBottom: 10,
                marginTop: -10,
                marginLeft: 5,
                border: "1px solid blue",
                borderRadius: 5,
              }}
              placeholder="search text"
              allowClear
              enterButton="Search"
              size="small"
              onChange={onChange}
            />
            {/* Erase all data, if removed comments below, will active */}
            <button
              style={{ marginLeft: 10, marginBottom: 8, width: 80 }}
              className="button-20"
              onClick={onEraseAll}
            >
              Erase all
            </button>
          </div>
          <div className="flex flex-col justify-between items-center">
            <h3>Admin area</h3>{" "}
            <small>
              Note: keep name of platform unique <br />
              for data integrity and app to work...
            </small>
          </div>
          <div className="flex flex-row justify-between items-center mt-1">
            {/* <button
              onClick={() => navigate("/")}
              className="button-5"
              style={{
                marginBottom: 16,
                marginRight: 7,
              }}
            >
              Home
            </button> */}
            <Tooltip title="Go home">
              <HomeTwoTone
                onClick={() => navigate("/")}
                style={{
                  fontSize: 33,
                  marginBottom: 16,
                  marginRight: 10,
                }}
              />
            </Tooltip>
            <button
              style={{ width: 80, marginRight: 5, marginTop: -16 }}
              onClick={showOnline}
              className="button-4"
            >
              Online
            </button>
            <button
              style={{ width: 80, marginRight: 5, marginTop: -16 }}
              onClick={showOffline}
              className="button-5"
            >
              Offline
            </button>
            {/* <button
              onClick={handleAdd}
              className="button-5"
              style={{
                marginBottom: 16,
                marginRight: 6,
              }}
            >
              Add platform
            </button> */}
            <Tooltip title="Single add">
              <PlusCircleTwoTone
                style={{
                  fontSize: 33,
                  marginLeft: 4,
                  marginBottom: 16,
                  marginRight: 10,
                }}
                onClick={handleAdd}
              />
            </Tooltip>
            {/* <button
              style={{ width: 110, marginRight: 3, marginTop: -16 }}
              // onClick={() => navigate("/upload")}
              onClick={onBulkUpload}
              className="button-4"
            >
              Bulk add
            </button> */}
            <Tooltip title="Bulk add">
              <SisternodeOutlined
                style={{ fontSize: 37, marginBottom: 16, marginRight: 10 }}
                onClick={onBulkUpload}
              />
            </Tooltip>
            <button
              style={{ width: 80, marginRight: 3, marginTop: -16 }}
              className="button-4"
              onClick={handlePrint}
            >
              Backup
            </button>
          </div>
        </div>
        {/* https://github.com/ant-design/ant-design/issues/7623 rowKey */}
        {!isoffline ? (
          <p
            style={{
              color: "red",
              marginInlineStart: 10,
              fontWeight: 700,
              fontSize: 15,
            }}
          >
            Offline platforms
          </p>
        ) : (
          <p
            style={{
              color: "blue",
              marginInlineStart: 10,
              fontWeight: 700,
              fontSize: 15,
            }}
          >
            Online platforms
          </p>
        )}
        <Table
          rowKey="id"
          bordered
          size="small"
          scroll={{ x: "max-content" }}
          className="table-striped-rows"
          rowClassName="editable-row"
          columns={colstodisplay}
          dataSource={filterData()}
          pagination={{
            position: ["topRight", "bottomRight"],
            defaultPageSize,
            pageSizeOptions: [defaultPageSize, "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "/ page" },
            onChange(pge, pSize) {
              setPage(pge);
              setPagesize(pSize);
            },
          }}
        />
        <Modal
          okButtonProps={{
            shape: "round",
            style: { backgroundColor: "lightgreen" },
          }}
          width="700px"
          cancelButtonProps={{
            shape: "round",
            style: { backgroundColor: "orange" },
          }}
          style={{
            top: 30,
            paddingBottom: 0,
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
            maxWidth: "800px",
          }}
          title={
            isAdding ? (
              <>
                <h3>Add Platform </h3>
                <p style={{ fontSize: 10 }}>
                  This is just a helper data to correctly fill up the new
                  plaform data in correct format.
                </p>
              </>
            ) : (
              <h3>Edit Platform</h3>
            )
          }
          open={isEditing}
          okText="Submit"
          onCancel={() => {
            resetEditing();
          }}
          onOk={() => {
            if (isEditing) {
              let aa = [...dataSource];
              aa?.map((platform) => {
                if (platform.name === editingPlatform.name) {
                  editPlatformfn(editingPlatform);
                  return editingPlatform;
                } else {
                  //    editPlatformfn(platform);
                  return platform;
                }
              });
              if (isEditing && !isAdding) {
                //only for edit case
                toast.success("Platform data updated", { duration: 4000 });
              }
            }
            if (isAdding) {
              // dispatch(SetDatasource([...dataSource, editingPlatform]));
              addPlatform([editingPlatform]);
              //[editingPlatform] as array val must go in.
              toast.success("New platform added successfully", {
                duration: 4000,
              });
            }
            resetEditing();
          }}
        >
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col justify-between items-center">
              <span className="flex flex-col myspan">
                <label htmlFor="date">Date</label>
                <input
                  id="date"
                  type="text"
                  value={editingPlatform?.date}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, date: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="platform">Platform</label>
                <input
                  id="platform"
                  type="text"
                  value={editingPlatform?.platform}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, platform: e.target.value };
                    });
                  }}
                />
              </span>

              <span className="flex flex-col myspan">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  value={editingPlatform?.name}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, name: e.target.value };
                    });
                  }}
                  readOnly={isAdding ? false : true}
                />
              </span>

              <span className="flex flex-col myspan">
                <label htmlFor="site">Site</label>
                <input
                  id="site"
                  type="text"
                  value={editingPlatform?.site}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, site: e.target.value };
                    });
                  }}
                />
              </span>

              <span className="flex flex-col myspan">
                <label htmlFor="status">Status</label>
                <input
                  id="status"
                  type="text"
                  value={editingPlatform?.status}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, status: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="workload">Workload</label>
                <input
                  id="workload"
                  type="text"
                  value={editingPlatform?.workload}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, workload: e.target.value };
                    });
                  }}
                />
              </span>

              <span className="flex flex-col myspan">
                <label htmlFor="activities_comments">Activities/Comments</label>
                <input
                  id="activities_comments"
                  type="text"
                  value={editingPlatform?.activities_comments}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, activities_comments: e.target.value };
                    });
                  }}
                />
              </span>
            </div>
            <div className="flex flex-col justify-between items-center">
              <span className="flex flex-col myspan">
                <label htmlFor="user">User</label>
                <input
                  id="user"
                  type="text"
                  value={editingPlatform?.user}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, user: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="team">Team</label>
                <input
                  id="team"
                  type="text"
                  value={editingPlatform?.team}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, team: e.target.value };
                    });
                  }}
                />
              </span>

              <span className="flex flex-col myspan">
                <label htmlFor="cpu1_sn">CPU1 SN</label>
                <input
                  id="cpu1_sn"
                  type="text"
                  value={editingPlatform?.cpu1_sn}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, cpu1_sn: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="cpu2_sn">CPU2 SN</label>
                <input
                  id="cpu2_sn"
                  type="text"
                  value={editingPlatform?.cpu2_sn}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, cpu2_sn: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="stack">STACK</label>
                <input
                  id="stack"
                  type="text"
                  value={editingPlatform?.stack}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, stack: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="socket">Socket</label>
                <input
                  id="socket"
                  type="text"
                  value={editingPlatform?.socket}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, socket: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="thread">Thread</label>
                <input
                  id="thread"
                  type="text"
                  value={editingPlatform?.thread}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, thread: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="total_ram">Total RAM</label>
                <input
                  id="total_ram"
                  type="text"
                  value={editingPlatform?.total_ram}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, total_ram: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="dimm_sticks">DIMM Sticks</label>
                <input
                  id="dimm_sticks"
                  type="text"
                  value={editingPlatform?.dimm_sticks}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, dimm_sticks: e.target.value };
                    });
                  }}
                />
              </span>
            </div>
            <div className="flex flex-col justify-between items-center">
              <span className="flex flex-col myspan">
                <label htmlFor="dimm_size">DIMM Size</label>
                <input
                  id="dimm_size"
                  type="text"
                  value={editingPlatform?.dimm_size}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, dimm_size: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="bios">BIOS</label>
                <input
                  id="bios"
                  type="text"
                  value={editingPlatform?.bios}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, bios: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="os">OS</label>
                <input
                  id="os"
                  type="text"
                  value={editingPlatform?.os}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, os: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="ssh">SSH</label>
                <input
                  id="ssh"
                  type="text"
                  value={editingPlatform?.ssh}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, ssh: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="bmc">BMC</label>
                <input
                  id="bmc"
                  type="text"
                  value={editingPlatform?.bmc}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, bmc: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="pdu">PDU</label>
                <input
                  id="pdu"
                  type="text"
                  value={editingPlatform?.pdu}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, pdu: e.target.value };
                    });
                  }}
                />
              </span>
              <span className="flex flex-col myspan">
                <label htmlFor="pdu_usr_pass">PDU User password</label>
                <input
                  type="text"
                  id="pdu_usr_pass"
                  value={editingPlatform?.pdu_usr_pass}
                  onChange={(e) => {
                    setEditingPlatform((pre) => {
                      return { ...pre, pdu_usr_pass: e.target.value };
                    });
                  }}
                />
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          okButtonProps={{
            shape: "round",
            style: { backgroundColor: "lightgreen" },
          }}
          width="300px"
          cancelButtonProps={{
            shape: "round",
            style: { backgroundColor: "orange" },
          }}
          style={{
            top: 80,
            paddingBottom: 0,
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
            maxWidth: "800px",
          }}
          title="Excel file bulk upload"
          open={bulk}
          footer={null}
          okText="Submit"
          onCancel={() => {
            resetBulk();
          }}
          onOk={() => {}}
        >
          <div className="flex flex-row justify-between items-center">
            <Uploadexcel />
          </div>
        </Modal>
      </header>
      <Toaster position="bottom-left" reverseOrder={false} />
    </div>
  );
}

export default Admin;
