/** @format */

import { createSlice } from "@reduxjs/toolkit";

const inoutSlice = createSlice({
  name: "platforms",
  initialState: {
    dataSource: [],
    closebulkmodal: false,
    isOffline: false,
  },
  reducers: {
    SetDatasource: (state, action) => {
      state.dataSource = action.payload;
    },
    SetCloseBulkModal: (state, action) => {
      state.closebulkmodal = action.payload;
    },
    SetisOffline: (state, action) => {
      state.isOffline = action.payload;
    },
  },
});

export const { SetDatasource, SetCloseBulkModal, SetisOffline } =
  inoutSlice.actions;

export default inoutSlice.reducer;
