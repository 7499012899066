/** @format */

import { axiosInstance } from ".";
import { apiUrl } from "./myAPI";

// make bulk post
export const PostBulk = async (myJSON) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}` + "/posts/newdata", {
      fws: myJSON,
    });
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
};

// get platform data from server
export const GetPlatData = async () => {
  try {
    const response = await axiosInstance.get(
      `${apiUrl}` + "/posts/getsavedfwdata"
    );
    //filtering only online is true records...
    var myfilt = response.data.filter((el) => el.online === true);
    return myfilt;
  } catch (error) {
    return error.response.data;
  }
};
// get platform data from server
export const GetAllPlatData = async () => {
  try {
    const response = await axiosInstance.get(
      `${apiUrl}` + "/posts/getsavedfwdata"
    );
    //getting all online and off line datas to use in backup.
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// get platform that are offline
export const OfflinePlatData = async () => {
  try {
    const response = await axiosInstance.get(
      `${apiUrl}` + "/posts/getsavedfwdata"
    );
    //filtering only online is true records...
    var myfilt = response.data.filter((el) => el.online === false);
    return myfilt;
  } catch (error) {
    return error.response.data;
  }
};
// add platform data from server
export const AddPlatData = async (record) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}` + "/posts/newdata", {
      fws: record,
    });
    var myfilt = response.data.data.filter((el) => el.online === true);
    return myfilt;
    //return response.data.data;
  } catch (error) {
    return error.response.data;
  }
};
// get platform data from server
export const EditPlatData = async (record) => {
  try {
    const response = await axiosInstance.post(
      `${apiUrl}` + "/posts/updatedata",
      {
        fws: [record],
      }
    );
    // var myfilt = response.data.data.fws.filter((el) => el.online === true);
    // return myfilt;
    return response.data.data.fws;
  } catch (error) {
    return error.response.data;
  }
};

// get call to delete platform data from server
export const DelPlatData = async (name) => {
  try {
    const response = await axiosInstance.get(
      `${apiUrl}` + "/posts/deljsons/" + name
    );
    return response.data; //[]
  } catch (error) {
    return error.response.data;
  }
};
// get call to delete ALL platform data from server
export const EraseALL = async () => {
  try {
    const response = await axiosInstance.get(`${apiUrl}` + "/posts/dall");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get call to update the BIOS using paramiko sssh at bck end
export const UpdateBIOS = async (name) => {
  try {
    const response = await axiosInstance.get(
      `${apiUrl}` + "/posts/biosinfo/" + name
    );
    return response.data; //[]
  } catch (error) {
    return error.response.data;
  }
};